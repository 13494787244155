import logo from "./logo.svg";
import "./App.css";
import "./index.css";
import Login from "./components/Login";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import UploadExcel from "./components/UploadExcel";
import VideoUpload from "./components/VideoUpload";
import NewUsers from "./components/NewUsers";
import Agreements from "./components/Agreements";
import PrivateRoute from "./components/PrivateRoute";
import Annexures from "./components/Annexures";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/home" element={<HomePage />} /> */}
        <Route path="/home" element={<PrivateRoute element={HomePage} />} />
        <Route path="/video-upload" element={<PrivateRoute element={VideoUpload} />} />
        <Route path="/upload-excel" element={<PrivateRoute element={UploadExcel} />} />
        <Route path="/new-users" element={<PrivateRoute element={NewUsers} />} />
        <Route path="/agreements" element={<PrivateRoute element={Agreements} />} />
        <Route path="/annexures" element={<PrivateRoute element={Annexures} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import UploadExcel from "./UploadExcel";
import axios from "axios";

export default function NewUsers() {
  const [newContent, setNewContent] = useState(true);
  const [approvedContent, setApprovedContent] = useState(false);
  const [disapprovedContent, setDisapprovedContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionType, setActionType] = useState("");
  const [moreClick, setMoreClick] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [entries, setEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [totalContentCount, setTotalContentCount] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchNewUsers = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          "https://artistaloud-backend.kollywoodhungama.com/v1/getNewUsers"
        );

        if (
          response.status === 200 &&
          response.data.message === "MIS New Users fetched successfully"
        ) {
          const newUserData = response.data.data.map((item) => ({
            userId: item.userId, // Unique identifier
            name: item.Name,
            email: item.email,
            dateOfAccountCreation: new Date(
              item.dateOfAccountCreation
            ).toLocaleDateString(), // Format the date
            contentCount: item.contentCount,
          }));

          setData(newUserData); // Update the data state with the API response
        } else {
          setError("Failed to fetch new users");
        }
      } catch (err) {
        setError("Failed to fetch new users");
      } finally {
        setLoading(false);
      }
    };

    fetchNewUsers();
  }, []);

  useEffect(() => {
    const fetchContentCount = async () => {
      try {
        const response = await axios.get(
          "https://artistaloud-backend.kollywoodhungama.com/v1/getContentCount"
        );

        if (response.status === 200) {
          const contentCountData = response.data.data[0]; // Extract the object from the array
          setTotalContentCount(contentCountData.totalContentCount); // Save `totalContentCount` directly
        } else {
          console.error("Unexpected API response structure", response);
          setError("Failed to fetch content count data.");
        }
      } catch (error) {
        console.error("Error fetching content count data:", error);
        setError("An error occurred while fetching the data.");
      } finally {
        setLoading(false);
      }
    };

    fetchContentCount();
  }, []);
  

  const formattedData = data.map((item) => ({
    userId: item.userId,
    name: item.Name,
    email: item.email,
    dateOfAccountCreation: item.dateOfAccountCreation,
    contentCount: item.contentCount,
  }));

  const handleDownloadCSV = () => {
    // Convert formattedData to CSV format
    const csvData = XLSX.utils.json_to_sheet(formattedData);
    const csvOutput = XLSX.utils.sheet_to_csv(csvData);

    // Create a Blob from the CSV output
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

    // Use file-saver to trigger download
    saveAs(blob, "new-users.csv");
  };

  const handleDownloadExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert formattedData to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "New-Users");

    // Generate the Excel file and trigger download
    XLSX.writeFile(workbook, "new-users.xlsx"); // Use .xlsx extension
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSort = () => {
    if (sortDirection === "asc") {
      setSortDirection("desc");
    } else {
      setSortDirection("asc");
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(totalEntries / entriesPerPage);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={`mx-1 ${i === currentPage ? "font-bold" : ""}`}
          onClick={() => setCurrentPage(i)}
        >
          {i < 10 ? `0${i}` : i}
        </button>
      );
    }
    return pages;
  };

  const totalEntries = data.length;
  // Step 1: Filter the data based on the search query
  const filteredData = data.filter((item) => {
    const matchesSearch =
      (item.Name &&
        item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(searchQuery.toLowerCase()));
    return matchesSearch;
  });

  // Step 2: Sort the filtered data by dateOfAccountCreation
  const filteredAndSortedData = filteredData.sort((a, b) => {
    if (sortDirection === "asc") {
      return (
        new Date(a.dateOfAccountCreation) - new Date(b.dateOfAccountCreation)
      );
    } else if (sortDirection === "desc") {
      return (
        new Date(b.dateOfAccountCreation) - new Date(a.dateOfAccountCreation)
      );
    }
    return 0;
  });

  // Step 3: Apply pagination to the filtered and sorted data
  const totalFilteredEntries = filteredAndSortedData.length; // Total entries after filtering and sorting
  const totalPages = Math.ceil(totalFilteredEntries / entriesPerPage); // Total number of pages after filtering

  const firstEntryIndex = (currentPage - 1) * entriesPerPage;
  const lastEntryIndex = Math.min(
    firstEntryIndex + entriesPerPage,
    totalFilteredEntries
  );

  // Slice the filtered and sorted data for pagination
  const paginatedData = filteredAndSortedData.slice(
    firstEntryIndex,
    lastEntryIndex
  );

  const renderTable = (data) => (
    <div className="scrollable-table ml-[5vw] w-[90vw] rounded-md">
      <table className="info w-full">
        <thead className="bg-[#252525] pb-4 ">
          <tr>
            <th
              className="mis-head rounded-l-md"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              No.
            </th>
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Name
            </th>
            <th
              className="min-w-[200px]"
              style={{
                fontSize: "11px",
                fontWeight: "200",
                color: "white",
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Email
            </th>
            <th
              className="mis-head"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Date of Account Creation
              <button onClick={handleSort} className="ml-2">
                {sortDirection === "asc" ? "🔼" : "🔽"}
              </button>
            </th>
            <th
              className="mis-head rounded-r-md"
              style={{
                position: "sticky",
                top: "0",
                background: "#252525",
                zIndex: "1",
              }}
            >
              Content Count
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="h-2 bg-transparent"></tr>
        </tbody>

        <tbody className="mt-[10%]">
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr key={item.no}>
                <td className="mis-data rounded-l-md">
                  {firstEntryIndex + index + 1}
                </td>
                <td className="mis-data">{item.name}</td>
                <td className="mis-data">{item.email}</td>
                <td className="mis-data">{item.dateOfAccountCreation}</td>
                <td className="mis-data rounded-r-md">{item.contentCount}</td>
              </tr>
              <tr className="gap-row">
                <td colSpan="2"></td>{" "}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
  return (
    <>
      <Header />
      <div className="flex ml-[5vw] mr-[5vw] mt-[5vh]">
        <div className="flex flex-col">
          <div className="flex items-center bg-[#1E1E1E] w-[90vw] h-[10vh] rounded-md">
            <div className="text-[#FF6C2F] text-[28px] ml-[5vw] font-medium">
              MIS
            </div>
            <div className="bg-[#FFFFFF] w-[1px] h-[25px] ml-[2vw]"></div>
            <Link
              to="/agreements"
              className="text-[#5B5B5B] text-[24px] ml-[2vw] font-medium"
            >
              Agreements
            </Link>
            <Link
              to="/new-users"
              className="text-white text-[24px] ml-[2vw] font-medium"
            >
              New Users
            </Link>
          </div>
          <br />
          <div className="flex justify-between bg-[#1E1E1E] w-[auto] h-[8vh] rounded-md text-white">
            <div
              className="flex items-center justify-center w-[29vw] rounded-md text-[1.1rem] text-white"
              style={{ fontWeight: "normal" }}
            >
              <h2>USERS AFTER 22 OCTOBER 2021</h2>
            </div>
            <div className="flex items-center justify-end bg-[#252525] w-[auto] h-[8vh] rounded-md p-[20px]">
              <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                <div className="flex items-center justify-center p-[15px]">
                  <h2 className="metasea-info">Total Content Count</h2>
                </div>
                <div
                  className="flex items-center justify-center p-[15px] bg-[black] rounded-md"
                  style={{ minWidth: "fit-content" }}
                >
                  <h2 className="metasea-info">{totalContentCount !== null ? totalContentCount : "No data "}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="flex justify-between ml-[5vw] w-[90vw] h-[7vh] text-[0.8rem] text-white mb-[1.5%]">
        <div className=" flex w-[18%] ml-[0.5%] flex justify-between">
          <button
            className="flex justify-center items-center bg-[#161616] h-[7vh] rounded-md w-[7vw] border-[1px] border-[#FF6C2F]"
            onClick={handleDownloadExcel}
          >
            Excel{" "}
            <IoMdDownload
              style={{
                color: "white",
                fontSize: "1.4rem",
                padding: "4px",
              }}
            />
          </button>
          <button
            className="flex justify-center items-center bg-[#161616] h-[7vh] rounded-md w-[7vw] border-[1px] border-[#FF6C2F]"
            onClick={handleDownloadCSV}
          >
            CSV{" "}
            <IoMdDownload
              style={{
                color: "white",
                fontSize: "1.4rem",
                padding: "4px",
              }}
            />
          </button>
        </div>
        <div className="w-[28%] ml-[0.5%] relative">
          <input
            type="search"
            name="searchBar"
            id=""
            className="bg-[#252525] text-white rounded-md outline-none border-[1px] border-[#FF6C2F] p-[10px] pl-[50px] w-full h-[7vh]"
            placeholder="Search.."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <div className="absolute top-[50%] left-[15px] transform -translate-y-[50%]">
            <IoSearchOutline className="text-white" />
          </div>
        </div>
      </div>
      <div className="mt-[2vh] mb-[2vh]">
        {renderTable(paginatedData)}{" "}
        <div className="flex justify-between items-center ml-[5vw] w-[90vw] h-[7vh] pl-[2%] pr-[2%] rounded-md bg-[#252525] text-white text-[0.7rem]">
          <div>
            Showing {firstEntryIndex + 1} to {lastEntryIndex} of {totalEntries}{" "}
            entries
          </div>
          <div className="flex items-center ">
            <span>Show </span>
            <select
              className="mx-2 p-1 border rounded bg-[#252525] outline-none text-white"
              value={entriesPerPage}
              onChange={handleEntriesPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span>entries</span>
          </div>
          <div className="flex items-center">
            <button
              className="mx-2"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="mx-2"
              // className={`p-2 bg-gray-200 rounded ${
              //   currentPage === Math.ceil(totalEntries / entriesPerPage) &&
              //   "opacity-50 cursor-not-allowed"
              // }`}
              onClick={goToNextPage}
              disabled={
                currentPage === Math.ceil(totalEntries / entriesPerPage)
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

import React, { useState, useEffect } from "react";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function UploadExcel() {
  const [fileName, setFileName] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [isIsrcClicked, setIsIsrcClicked] = useState(false);
  const [isUpcClicked, setIsUpcClicked] = useState(false);
  const [isCatalogueClicked, setIsCatalogueClicked] = useState(false);
  const [codes, setCodes] = useState({
    isrc: '',
    upc: '',
    catalogue: '',
  });

  useEffect(() => {
    // Fetch the codes from the API
    fetch('https://artistaloud-backend.kollywoodhungama.com/v1/getCodes')
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          // Extracting the CNT values based on the CODE_TYPE
          const codeData = data.data.reduce((acc, item) => {
            if (item.CODE_TYPE === 'ISRC') {
              acc.isrc = item.CNT;
            } else if (item.CODE_TYPE === 'UPC') {
              acc.upc = item.CNT;
            } else if (item.CODE_TYPE === 'CATELOGUE') {
              acc.catalogue = item.CNT;
            }
            return acc;
          }, {});
          
          setCodes(codeData);
        }
      })
      .catch((error) => {
        console.error('Error fetching codes:', error);
      });
  }, []);

  const validateFileType = (file) => {
    const validTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls
      "text/csv", // .csv
    ];
    return validTypes.includes(file.type);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateFileType(file)) {
        setFileURL(file);  // Store the actual file object, not the object URL
        setFileName(file.name);
      } else {
        toast.error("Only Excel and CSV files are allowed.");
      }
    }
  };
  

  const handleIsrcClick = () => {
    setIsIsrcClicked(!isIsrcClicked);
    setIsUpcClicked(false);
    setIsCatalogueClicked(false);
  };

  const handleUpcClick = () => {
    setIsIsrcClicked(false);
    setIsUpcClicked(!isUpcClicked);
    setIsCatalogueClicked(false);
  };
  const handleCatalogueClick = () => {
    setIsIsrcClicked(false);
    setIsUpcClicked(false);
    setIsCatalogueClicked(!isCatalogueClicked);
  };

  const customStyle = {
    backgroundColor: "#FF6C2F",
    color: "white",
    cursor: "pointer",
    transition: "transform 0.1s ease-in-out",
    transform: "scale(0.95)",
  };

  // const handleSubmit = () => {
  //   isIsrcClicked || isUpcClicked || isCatalogueClicked
  //     ? fileURL && fileName
  //       ? toast.success("File uploaded successfully") && clearAllFields()
  //       : toast.error("Please upload a file!")
  //     : toast.error("Please select a Code Type!");
  // };

  const handleSubmit = () => {
    if (isIsrcClicked || isUpcClicked || isCatalogueClicked) {
      if (fileURL && fileName) {
        const formData = new FormData();
        formData.append("file", fileURL);  // Append the actual file to the formData
  
        let apiEndpoint = '';
        if (isIsrcClicked) {
          apiEndpoint = 'ISRC';
        } else if (isUpcClicked) {
          apiEndpoint = 'UPC';
        } else if (isCatalogueClicked) {
          apiEndpoint = 'CATELOGUE';
        }
  
        // Make the API call
        fetch(`https://artistaloud-backend.kollywoodhungama.com/v1/uploadCsv/${apiEndpoint}`, {
          method: 'POST',
          headers: {
            // No need to set Content-Type as FormData will handle it
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.result.statusCode === 200) {
              toast.success(data.result.message);
              clearAllFields();
            } else {
              toast.error("Error: " + (data.result.message || "Something went wrong."));
            }
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
            toast.error("Something went wrong. Please try again.");
          });
      } else {
        toast.error("Please upload a file!");
      }
    } else {
      toast.error("Please select a Code Type!");
    }
  };
  
  

  const handleCancel = () => {
    clearAllFields();
  };

  const clearAllFields = () => {
    setFileName("");
    setFileURL("");
    setIsIsrcClicked(false);
    setIsUpcClicked(false);
    setIsCatalogueClicked(false);
  };

  return (
    <div>
      <Header />
      <div className="flex ml-[5vw] mr-[5vw] mt-[5vh] w-[90vw]">
        <div className="flex items-center justify-center w-[25vw] ml-[3vw]">
          <img
            src="./assets/aa_logo.png"
            className="w-[auto] h-[22vh] p-[5px] rotate-logo"
            alt=""
          />
        </div>
        <div className="flex flex-col ml-[5vw]">
          <div className="flex items-center bg-[#1E1E1E] w-[70vw] h-[8vh] rounded-md">
            <Link to="/home" className="text-white text-[16px] ml-[5vw]">
              Audio Upload
            </Link>
            <Link to="/video-upload" className="text-white text-[16px] ml-[5vw]">
              Video Upload
            </Link>
            <Link to="/upload-excel" className="text-[#FEC961] text-[16px] ml-[5vw]">
              Upload Excel
            </Link>
          </div>
          <br />
          <div className="flex bg-[#1E1E1E] w-[auto] h-[15vh] rounded-md text-white">
            <div
              className="flex items-center justify-center w-[40%] rounded-md text-[1.7rem] text-[#FF6C2F]"
              style={{ fontWeight: "normal" }}
            >
              <h2>Upload Excel</h2>
            </div>
            <div className="flex flex-col bg-[#252525] w-[60%] h-[15vh] rounded-md ">
              <div className="flex items-center h-[50%] p-[10px] pl-[38px]">
                <h2 className="metasea-info">
                  Code available for Metasea Ingestion{" "}
                </h2>
              </div>
              <div className="flex items-center justify-around w-[100%]">
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">ISRC</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">{codes.isrc || 'Loading...'}</h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">UPC</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">{codes.upc || 'Loading...'}</h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center bg-[#161616] p-[10px] rounded-md">
                    <h2 className="metasea-info">CATALOGUE</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">{codes.catalogue || 'Loading...'}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col bg-[#1E1E1E] ml-[5vw] mr-[5vw] mt-[5vh] w-[90vw] rounded-md p-[4%] mb-[5%]">
        <h1 className="text-[#FEC961] text-[1.2rem] mb-[0.5%]">
          Upload ISRC / UPC / CATALOGUE ID
        </h1>
        <div className="w-[330px] h-[1px] mb-[2%] bg-[#FEC961] rounded-md"></div>
        <label htmlFor="" className="text-white text-[0.9rem] mb-[0.6%]">
          Select Code Type
        </label>
        <div className=" mb-[2%]">
          <button
            className="text-[#c5c5c5] bg-[#131313] text-[0.9rem] p-[12px] rounded-md hover:bg-[#FF6C2F] hover:text-white active:bg-[#c75525]"
            onClick={handleIsrcClick}
            style={isIsrcClicked ? customStyle : null}
          >
            ISRC
          </button>
          <button
            className="text-[#c5c5c5] text-[0.9rem] p-[12px] bg-[#131313] rounded-md ml-[1%] hover:bg-[#FF6C2F] hover:text-white active:bg-[#c75525]"
            onClick={handleUpcClick}
            style={isUpcClicked ? customStyle : null}
          >
            UPC
          </button>
          <button
            className="text-[#c5c5c5] text-[0.9rem] p-[12px] bg-[#131313] rounded-md ml-[1%] hover:bg-[#FF6C2F] hover:text-white active:bg-[#c75525]"
            onClick={handleCatalogueClick}
            style={isCatalogueClicked ? customStyle : null}
          >
            CATALOGUE
          </button>
        </div>
        <div className="w-[110px] h-[1px] mb-[2%] bg-[#FFFFFF] rounded-md "></div>
        <label htmlFor="" className="text-white text-[0.9rem] mb-[0.5%]">
          Upload Excel
        </label>
        <div className="flex items-center mb-[2%]">
          <label
            htmlFor="choose-excel"
            className=" bg-[#131313] text-[#c5c5c5] text-[0.9rem] p-[12px] rounded-md hover:text-white"
          >
            Choose File
          </label>
          <input
            type="file"
            name=""
            id="choose-excel"
            hidden
            onChange={handleFileUpload}
          />
          <span
            className="text-[#FEC961] text-[0.6rem] ml-[1%] w-[15%] h-[5%] max-h-[6%] overflow-hidden"
            style={{ textDecorationLine: "underline" }}
          >
            {fileName && (
              <div className="image-info">
                <a
                  className="file-name"
                  href={fileURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fileName}
                </a>
              </div>
            )}
          </span>
        </div>
        <div className="w-[110px] h-[1px] mb-[2%] bg-[#FFFFFF] rounded-md "></div>
        <div className="flex">
          <button
            className="bg-[#FEC961] text-[0.9rem] text-black rounded-md hover:bg-[#FF6C2F] hover:text-white active:bg-[#c75525] p-[12px]"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="bg-[#131313] text-[0.9rem] text-white rounded-md hover:bg-[#484848] active:bg-[#484848] p-[12px] ml-[2%]"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Header from "./Header";
import { IoSearchOutline } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Popup from "./Popup";

export default function VideoUpload() {
  const [newContent, setNewContent] = useState(true);
  const [approvedContent, setApprovedContent] = useState(false);
  const [disapprovedContent, setDisapprovedContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionType, setActionType] = useState("");
  const [moreClick, setMoreClick] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [artworkUrl, setArtworkUrl] = useState("");
  const [newData, setNewData] = useState([]);
  const [approvedData, setApprovedData] = useState([]);
  const [disapprovedData, setDisapprovedData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [mediaElement, setMediaElement] = useState(null);
  const [playingItem, setPlayingItem] = useState(null);
  const [codes, setCodes] = useState({
    isrc: "",
    upc: "",
    catalogue: "",
  });

  const filteredData = (data) => {
    if (!Array.isArray(data)) {
      return []; // If the data is not an array, return an empty array
    }

    console.log("Data before filtering:", data); // Log the data for debugging

    return data.filter((item) => {
      const matchesSearch =
        (item.userName &&
          item.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.trackName &&
          item.trackName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.trackActor &&
          item.trackActor.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.explicit &&
          item.explicit
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        (item.albumName &&
          item.albumName.toLowerCase().includes(searchQuery.toLowerCase()));

      return matchesSearch;
    });
  };

  const filteredNewData = filteredData(newData);
  const filteredApprovedData = filteredData(approvedData);
  const filteredDisapprovedData = filteredData(disapprovedData);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  const handleNewClick = () => {
    setNewContent(true);
    setApprovedContent(false);
    setDisapprovedContent(false);
  };

  const handleApprovedClick = () => {
    setNewContent(false);
    setApprovedContent(true);
    setDisapprovedContent(false);
  };

  const handleDisapprovedClick = () => {
    setNewContent(false);
    setApprovedContent(false);
    setDisapprovedContent(true);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleActionClick = (item, type) => {
    setSelectedRow(item);
    setActionType(type);
    setShowConfirm(true);
  };

  const handleDisapproveClick = (item, type) => {
    setSelectedRow(item);
    setActionType(type);
    setShowConfirm(true);
  };

  const closePopup = () => {
    setShowConfirm(false); // Close the popup
    setSelectedReason(""); // Reset the selected reason, if needed
  };

  const handleDisapproveConfirm = () => {
    console.log("selectedRow:", selectedRow); // Debugging log

    const content_id = selectedRow?.content_id;
    const userId = selectedRow?.user_id;
    const actorName = selectedRow?.artist_name; // Changed to artist_name
    const songName = selectedRow?.track_name; // Changed to track_name
    const distributionType = selectedRow?.distribution_type; // Changed to distribution_type

    // Ensure the necessary fields are available
    if (
      !content_id ||
      !userId ||
      !actorName ||
      !songName ||
      !distributionType ||
      !selectedReason
    ) {
      console.error("Missing required fields for disapprove operation");
      return;
    }

    const payload = {
      userId,
      actorName,
      songName,
      distributionType,
      reason: selectedReason,
    };

    console.log("Sending payload:", payload);

    fetch(
      `https://artistaloud-backend.kollywoodhungama.com/v1/updateStatus/Disapproved/${content_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to disapprove item");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Disapprove response:", data);

        if (data.status === 200) {
          // Now update the UI state only if the API request is successful
          setNewData((prevData) =>
            prevData.filter((item) => item.content_id !== content_id)
          );
          setDisapprovedData((prevData) => [
            ...prevData,
            { ...selectedRow, status: "Disapproved", reason: selectedReason },
          ]);

          // Close the popup
          closePopup();

          // Refresh the page after a short delay to let UI update
          setTimeout(() => {
            window.location.reload();
          }, 100);
        } else {
          console.error("Disapproval failed:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error disapproving item:", error);
      });
  };

  const handleMoreClick = () => {
    setMoreClick(!moreClick);
  };

  const handleEditClick = () => {
    setShowComponent(!showComponent);
  };

  const handlePreviewClick = (artworkUrl) => {
    setShowPreview(!showPreview);
    // Optionally, store the artwork URL in a state if you want to manage it separately
    setArtworkUrl(artworkUrl);
  };

  const handleDeleteClick = (item) => {
    // Set the item to be deleted and show the confirmation popup
    setSelectedRow(item);
    setShowConfirmDelete(true); // Show the confirmation popup for deletion
  };

  const handleDeleteConfirm = () => {
    const content_id = selectedRow?.content_id;

    if (!content_id) {
      console.error("Release ID is missing for delete operation");
      return;
    }

    console.log("Deleting item with content_id:", content_id);

    fetch(
      `https://artistaloud-backend.kollywoodhungama.com/v1/deleteAudioVideo/${content_id}`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete item");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Delete response:", data);

        // If successful, remove the item from the newData
        setNewData((prevData) =>
          prevData.filter((item) => item.content_id !== content_id)
        );

        // Close the popup and refresh the page
        setShowConfirmDelete(false); // Close the confirmation popup

        // Refresh the page after a short delay to let the UI update
        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
      });
  };

  const handleConfirm = () => {
    if (actionType === "approve") {
      setData((prevData) =>
        prevData.map((item) =>
          item.no === selectedRow.no ? { ...item, status: "approved" } : item
        )
      );
      toast.success("Audio content approved and data ingested in metasea.");
    } else if (actionType === "disapprove") {
      setData((prevData) =>
        prevData.map((item) =>
          item.no === selectedRow.no ? { ...item, status: "disapproved" } : item
        )
      );
    }
    setShowConfirm(false);
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handlePlayClick = (item) => {
    let downloadUrl = null;
  
    if (item.audio) {
      downloadUrl = item.audio; // Audio URL
      window.open(downloadUrl, "_blank");
    } else if (item.video) {
      downloadUrl = item.video; // Video URL
      window.open(downloadUrl, "_blank");
    }
  };

  // const handleCloseMedia = () => {
  //   // Stop and remove the media element
  //   if (mediaElement) {
  //     mediaElement.pause();
  //     mediaElement.currentTime = 0;
  //     setMediaElement(null);
  //   }
  // };

  const handleDownloadClick = (item) => {
    // Check if the item has an audio or video URL, and set the link accordingly
    let downloadUrl = null;
  
    if (item.audio) {
      downloadUrl = item.audio; // Audio URL
    } else if (item.video) {
      downloadUrl = item.video; // Video URL
    }
  
    if (downloadUrl) {
      // Open the download link in a new page/tab
      window.open(downloadUrl, "_blank");
    } else {
      console.error("No audio or video URL available for download.");
    }
  };
  

  // const renderTable = (status) => (
  //   <div className="scrollable-table ml-[5vw] w-[90vw] rounded-md">
  //     <table className="info w-full">
  //       <thead className="bg-[#252525] pb-4">
  //         <tr>
  //           <th className="autnh-1 rounded-l-md">No.</th>
  //           <th className="autnh-1">User Name</th>
  //           <th className="autnh-1">Track Actor</th>
  //           <th className="autnh-1">Track Name</th>
  //           <th className="autnh-1">Album Name</th>
  //           <th className="autnh-1">Release Date</th>
  //           <th className="autnh-1">Uploaded On</th>
  //           <th className="autnh-1">Distribution</th>
  //           <th className="autnh-1">Artwork</th>
  //           <th className="autnh-1">Edit</th>
  //           <th className="autnh-1">Explicit</th>
  //           <th className="autnh-1 rounded-r-md bg-[#3B3B3B]">
  //             Actions to be performed
  //           </th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         <tr className="h-2 bg-transparent"></tr>
  //       </tbody>
  //       <tbody className="mt-[10%]">
  //         {filteredData
  //           .filter((item) => item.status === status)
  //           .map((item, index) => (
  //             <React.Fragment key={index}>
  //               <tr>
  //                 <td className="autnr rounded-l-md">{index + 1}</td>
  //                 <td className="autnr">{item.userName}</td>
  //                 <td className="autnr">{item.trackActor}</td>
  //                 <td className="autnr">{item.trackName}</td>
  //                 <td className="autnr">{item.albumName}</td>
  //                 <td className="autnr">{item.releaseDate}</td>
  //                 <td className="autnr">{item.uploadedOn}</td>
  //                 <td className="autnr">{item.distribution}</td>
  //                 <td className="autnr">
  //                   <button
  //                     className="text-blue-400"
  //                     onClick={handlePreviewClick}
  //                   >
  //                     View
  //                   </button>
  //                 </td>
  //                 <td className="autnr">
  //                   <button className="text-blue-400" onClick={handleMoreClick}>
  //                     More
  //                   </button>
  //                 </td>
  //                 <td className="autnr">{item.explicit}</td>
  //                 <td className="bg-[#252525] h-[100%] flex justify-around p-[10px] rounded-r-md">
  //                   {item.status === "new" && (
  //                     <>
  //                       <button className="bg-[white] rounded-3xl ">
  //                         <FaPlay
  //                           style={{
  //                             color: "black",
  //                             fontSize: "1.4rem",
  //                             padding: "6px",
  //                           }}
  //                         />
  //                       </button>
  //                       <button
  //                         className="bg-[#6CC72B] rounded-3xl"
  //                         onClick={() => handleActionClick(item, "approve")}
  //                       >
  //                         <TiTick
  //                           style={{ color: "black", fontSize: "1.4rem" }}
  //                         />
  //                       </button>
  //                       <button
  //                         className="bg-[#FF2947] rounded-3xl"
  //                         onClick={() =>
  //                           handleDisapproveClick(item, "disapprove")
  //                         }
  //                       >
  //                         <ImCross
  //                           style={{
  //                             color: "black",
  //                             fontSize: "1.4rem",
  //                             padding: "6px",
  //                           }}
  //                         />
  //                       </button>
  //                       <button className="bg-[#FFB900] rounded-3xl">
  //                         <IoMdDownload
  //                           style={{
  //                             color: "black",
  //                             fontSize: "1.4rem",
  //                             padding: "4px",
  //                           }}
  //                         />
  //                       </button>
  //                       <button className="">
  //                         <RiDeleteBin6Line
  //                           style={{ color: "white", fontSize: "1.4rem" }}
  //                         />
  //                       </button>
  //                     </>
  //                   )}
  //                   {item.status === "approved" && (
  //                     <button className="bg-[white] rounded-3xl ">
  //                       <FaPlay
  //                         style={{
  //                           color: "black",
  //                           fontSize: "1.4rem",
  //                           padding: "6px",
  //                         }}
  //                       />
  //                     </button>
  //                   )}
  //                   {item.status === "disapproved" && (
  //                     <>
  //                       <button className="bg-[white] rounded-3xl ">
  //                         <FaPlay
  //                           style={{
  //                             color: "black",
  //                             fontSize: "1.4rem",
  //                             padding: "6px",
  //                           }}
  //                         />
  //                       </button>
  //                       <button
  //                         className="bg-[#6CC72B] rounded-3xl"
  //                         onClick={() => handleActionClick(item, "approve")}
  //                       >
  //                         <TiTick
  //                           style={{ color: "black", fontSize: "1.4rem" }}
  //                         />
  //                       </button>
  //                     </>
  //                   )}
  //                 </td>
  //               </tr>
  //               <tr className="gap-row">
  //                 <td colSpan="2"></td>{" "}
  //               </tr>
  //             </React.Fragment>
  //           ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );

  const renderTable = (data) => {
    console.log("Rendering table with data:", data);

    return (
      <div className="scrollable-table ml-[5vw] w-[90vw] rounded-md">
        <table className="info w-full">
          <thead className="bg-[#252525] pb-4">
            <tr>
              <th className="autnh-1 rounded-l-md">No.</th>
              <th className="autnh-1">User Name</th>
              <th className="autnh-1">Track Actor</th>
              <th className="autnh-1">Track Name</th>
              <th className="autnh-1">Album Name</th>
              <th className="autnh-1">Release Date</th>
              <th className="autnh-1">Uploaded On</th>
              <th className="autnh-1">Distribution</th>
              <th className="autnh-1">Artwork</th>
              <th className="autnh-1">Edit</th>
              <th className="autnh-1">Explicit</th>
              <th className="autnh-1 rounded-r-md bg-[#3B3B3B]">
                Actions to be performed
              </th>
            </tr>
          </thead>
          <tbody className="mt-[10%]">
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td className="autnr rounded-l-md">{index + 1}</td>
                    <td className="autnr">{item.userName || "Unknown"}</td>
                    <td className="autnr">{item.artist_name || "Unknown"}</td>
                    <td className="autnr">{item.track_name || "Unknown"}</td>
                    <td className="autnr">
                      {item.artist_legal_name || "Unknown"}
                    </td>
                    <td className="autnr">
                      {formatDate(item.release_date) || "Unknown"}
                    </td>
                    <td className="autnr">
                      {formatDate(item.updated_at) || "Unknown"}
                    </td>
                    <td className="autnr">
                      {item.distribution_type || "Unknown"}
                    </td>
                    <td className="autnr">
                    <a
                        className="text-blue-400"
                        // onClick={() => handlePreviewClick()}
                        href={item.artwork}
                        target="_blank"
                      >
                        View
                      </a>
                      {/* {showPreview && item.artwork && (
                        <div className="artwork-preview">
                          <img
                            src={item.artwork || "default_image_url"} // Replace with actual default image URL
                            alt="Artwork"
                            className="w-20 h-20"
                          />
                        </div>
                      )} */}
                    </td>
                    <td className="autnr">
                      <button
                        className="text-blue-400"
                        onClick={() => handleMoreClick(item)}
                      >
                        More
                      </button>
                    </td>
                    <td className="autnr">
                      {item.explicit_content ? "Yes" : "No"}
                    </td>
                    {console.log("Item status:", item.status)}
                    <td className="bg-[#252525] h-[100%] flex justify-around p-[10px] rounded-r-md">
                      {item.status === "New" && (
                        <>
                          <button
                            className="bg-[white] rounded-3xl "
                            onClick={() => handlePlayClick(item)}
                          >
                            <FaPlay
                              style={{
                                color: "black",
                                fontSize: "1.4rem",
                                padding: "6px",
                              }}
                            />
                          </button>
                          <button
                            className="bg-[#6CC72B] rounded-3xl"
                            onClick={() => handleActionClick(item, "Approve")}
                          >
                            <TiTick
                              style={{ color: "black", fontSize: "1.4rem" }}
                            />
                          </button>
                          <button
                            className="bg-[#FF2947] rounded-3xl"
                            onClick={() =>
                              handleDisapproveClick(item, "Disapprove")
                            }
                          >
                            <ImCross
                              style={{
                                color: "black",
                                fontSize: "1.4rem",
                                padding: "6px",
                              }}
                            />
                          </button>
                          <button
                            className="bg-[#FFB900] rounded-3xl"
                            onClick={() => handleDownloadClick(item)}
                          >
                            <IoMdDownload
                              style={{
                                color: "black",
                                fontSize: "1.4rem",
                                padding: "4px",
                              }}
                            />
                          </button>
                          <button
                            className=""
                            onClick={() => handleDeleteClick(item)}
                          >
                            <RiDeleteBin6Line
                              style={{ color: "white", fontSize: "1.4rem" }}
                            />
                          </button>
                        </>
                      )}
                      {item.status === "Approved" && (
                        <>
                          <button
                            className="bg-[white] rounded-3xl "
                            onClick={() => handlePlayClick(item)}
                          >
                            <FaPlay
                              style={{
                                color: "black",
                                fontSize: "1.4rem",
                                padding: "6px",
                              }}
                            />
                          </button>
                        </>
                      )}
                      {item.status === "Disapproved" && (
                        <>
                          <button
                            className="bg-[white] rounded-3xl "
                            onClick={() => handlePlayClick(item)}
                          >
                            <FaPlay
                              style={{
                                color: "black",
                                fontSize: "1.4rem",
                                padding: "6px",
                              }}
                            />
                          </button>
                          <button
                            className="bg-[#6CC72B] rounded-3xl"
                            onClick={() => handleActionClick(item, "approve")}
                          >
                            <TiTick
                              style={{ color: "black", fontSize: "1.4rem" }}
                            />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr className="bg-[#1E1E1E]">
                <td colSpan="12" className="text-center text-white">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    // Fetching New Data
    fetch(
      "https://artistaloud-backend.kollywoodhungama.com/v1/allAudioVideo/video/New"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("New data:", data); // Debugging line
        setNewData(data.data);
      })
      .catch((error) => console.log(error));

    // Fetching Approved Data
    fetch(
      "https://artistaloud-backend.kollywoodhungama.com/v1/allAudioVideo/video/Approved"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Approved data:", data); // Debugging line
        setApprovedData(data.data);
      })
      .catch((error) => console.log(error));

    // Fetching Disapproved Data
    fetch(
      "https://artistaloud-backend.kollywoodhungama.com/v1/allAudioVideo/video/Disapproved"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Disapproved data:", data); // Debugging line
        setDisapprovedData(data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    // Fetch the codes from the API
    fetch("https://artistaloud-backend.kollywoodhungama.com/v1/getCodes")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          // Extracting the CNT values based on the CODE_TYPE
          const codeData = data.data.reduce((acc, item) => {
            if (item.CODE_TYPE === "ISRC") {
              acc.isrc = item.CNT;
            } else if (item.CODE_TYPE === "UPC") {
              acc.upc = item.CNT;
            } else if (item.CODE_TYPE === "CATELOGUE") {
              acc.catalogue = item.CNT;
            }
            return acc;
          }, {});

          setCodes(codeData);
        }
      })
      .catch((error) => {
        console.error("Error fetching codes:", error);
      });
  }, []);

  return (
    <div>
      <Header />
      <div className="flex ml-[5vw] mr-[5vw] mt-[5vh] w-[90vw]">
        <div className="flex items-center justify-center w-[25vw] ml-[3vw]">
          <img
            src="./assets/aa_logo.png"
            className="w-[auto] h-[22vh] p-[5px] rotate-logo"
            alt=""
          />
        </div>
        <div className="flex flex-col ml-[5vw]">
          <div className="flex items-center bg-[#1E1E1E] w-[70vw] h-[8vh] rounded-md">
            <Link to="/home" className="text-white text-[16px] ml-[5vw]">
              Audio Upload
            </Link>
            <Link
              to="/video-upload"
              className="text-[#FEC961] text-[16px] ml-[5vw]"
            >
              Video Upload
            </Link>
            <Link
              to="/upload-excel"
              className="text-white text-[16px] ml-[5vw]"
            >
              Upload Excel
            </Link>
          </div>
          <br />
          <div className="flex bg-[#1E1E1E] w-[auto] h-[15vh] rounded-md text-white">
            <div
              className="flex items-center justify-center w-[40%] rounded-md text-[1.7rem] text-[#FF6C2F]"
              style={{ fontWeight: "normal" }}
            >
              <h2>User Video Upload</h2>
            </div>
            <div className="flex flex-col bg-[#252525] w-[60%] h-[15vh] rounded-md ">
              <div className="flex items-center h-[50%] p-[10px] pl-[38px]">
                <h2 className="metasea-info">
                  Code available for Metasea Ingestion{" "}
                </h2>
              </div>
              <div className="flex items-center justify-around w-[100%]">
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">ISRC</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">
                      {codes.isrc || "Loading..."}
                    </h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center p-[10px]">
                    <h2 className="metasea-info">UPC</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">
                      {codes.upc || "Loading..."}
                    </h2>
                  </div>
                </div>
                <div className="flex w-[auto] h-[2.2rem] bg-[#161616] rounded-md">
                  <div className="flex items-center justify-center bg-[#161616] p-[10px] rounded-md">
                    <h2 className="metasea-info">CATALOGUE</h2>
                  </div>
                  <div
                    className="flex items-center justify-center p-[10px] bg-[black] rounded-md"
                    style={{ minWidth: "fit-content" }}
                  >
                    <h2 className="metasea-info">
                      {codes.catalogue || "Loading..."}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="flex ml-[5vw] w-[90vw] h-[7vh] text-[0.8rem] text-white mb-[1.5%]">
        <button
          className={`w-[17%] flex items-center justify-center ${
            newContent ? "bg-[#FF6C2F]" : "bg-[black]"
          }`}
          style={{ borderRadius: "10px 0px 0px 10px" }}
          onClick={handleNewClick}
        >
          New
        </button>
        <button
          className={`w-[17%] ml-[0.5%] flex items-center justify-center ${
            approvedContent ? "bg-[#FF6C2F]" : "bg-[black]"
          }`}
          onClick={handleApprovedClick}
        >
          Approved
        </button>
        <button
          className={`w-[17%] ml-[0.5%] flex items-center justify-center ${
            disapprovedContent ? "bg-[#FF6C2F]" : "bg-[black]"
          }`}
          style={{ borderRadius: "0px 10px 10px 0px" }}
          onClick={handleDisapprovedClick}
        >
          Disapproved
        </button>
        <div className="w-[48%] ml-[0.5%] relative">
          <input
            type="search"
            name="searchBar"
            id=""
            className="bg-[#252525] text-white rounded-md outline-none p-[10px] pl-[50px] w-full h-[7vh]"
            placeholder="Search.."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <div className="absolute top-[50%] left-[15px] transform -translate-y-[50%]">
            <IoSearchOutline className="text-white" />
          </div>
        </div>
      </div>
      <div className="mt-[2vh]">{newContent && renderTable(newData)}</div>
      <div className="mt-[2vh]">
        {approvedContent && renderTable(approvedData)}
      </div>
      <div className="mt-[2vh]">
        {disapprovedContent && renderTable(disapprovedData)}
      </div>
      {showConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-40">
          <div className="bg-[#171717] p-6 rounded-md text-white w-96">
            <h2 className="text-xl mb-4">
              Are you sure you want to disapprove?
            </h2>
            <form className="space-y-4">
              {/* Dropdown for Reasons */}
              <div>
                <label className="block text-sm font-medium">
                  Reason for Disapproval
                </label>
                <select
                  className="w-full p-2 rounded-md bg-gray-800 text-white"
                  value={selectedReason}
                  onChange={(e) => setSelectedReason(e.target.value)}
                >
                  <option value="" disabled>
                    Select a reason
                  </option>
                  <option value="Please don't mention any music label, recording studios during the track">
                    Please don't mention any music label, recording studios
                    during the track
                  </option>
                  <option value="Please ensure the audio isn't muted during the track">
                    Please ensure the audio isn't muted during the track
                  </option>
                  <option value="Please ensure that the track begins in maximum 5 seconds">
                    Please ensure that the track begins in maximum 5 seconds
                  </option>
                  <option value="Please ensure that the track is properly trimmed particularly towards the end">
                    Please ensure that the track is properly trimmed
                    particularly towards the end
                  </option>
                  <option value="Please don't upload any cover songs">
                    Please don't upload any cover songs
                  </option>
                  <option value="Please don't upload any poem or podcast content">
                    Please don't upload any poem or podcast content
                  </option>
                  <option value="Please add title on the artwork in sync with the track name">
                    Please add title on the artwork in sync with the track name
                  </option>
                  <option value="Please don't add any social media handles, music platforms logos and personal or office contact details">
                    Please don't add any social media handles, music platforms
                    logos and personal or office contact details
                  </option>
                </select>
              </div>
            </form>

            {/* Action Buttons */}
            <div className="flex justify-around mt-4">
              <button
                className="bg-[#FF6C2F] text-white px-4 py-2 rounded-md hover:bg-[#FF5733] active:bg-[#c75525]"
                onClick={handleDisapproveConfirm}
              >
                Yes
              </button>
              <button
                className="bg-[#3B3B3B] text-white px-4 py-2 rounded-md hover:bg-[#484848] active:bg-[#484848]"
                onClick={() => setShowConfirm(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Conditionally render the media player and close button */}
      {/* {mediaElement && playingItem && (
        <div className="media-container">
          <div className="player-wrapper">
            {playingItem.type === "video" && (
              <video
                src={encodeURI(playingItem.video)}
                controls
                autoPlay
                width="100%"
                height="auto"
              />
            )}

            <button className="close-button" onClick={handleCloseMedia}>
              x
            </button>
          </div>
        </div>
      )} */}

      {showPreview && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#161616] bg-opacity-70 ">
          <div className=" bg-[#555555] p-4 rounded-md w-[50vw] max-w-screen-lg h-[50vh] overflow-x-auto flex justify-center items-center">
            <div className="">
              <button
                className="absolute top-2 right-2 bg-transparent hover:bg-[#363636] active:bg-[#535353] text-white w-8 h-8 rounded-full flex items-center justify-center"
                onClick={() => setShowPreview(false)}
              >
                X
              </button>
              <video src="" controls className="w-full h-full object-contain">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
      {moreClick && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#161616] bg-opacity-90">
          <div className="bg-[#555555] p-4 rounded-md w-full max-w-screen-lg h-[40vh] overflow-x-auto">
            <button
              className="absolute top-2 right-2 bg-transparent hover:bg-[#363636] active:bg-[#535353] text-white w-8 h-8 rounded-full flex items-center justify-center"
              onClick={() => setMoreClick(false)}
            >
              X
            </button>
            <table className="info w-full rounded-md">
              <thead className="bg-[#252525] pb-4">
                <tr>
                  <th className="autnh-1 rounded-l-md border-r border-gray-600">
                    LANGUAGE
                  </th>
                  <th className="autnh-1 border-r border-gray-600">GENRE</th>
                  <th className="autnh-1 border-r border-gray-600">
                    SUB GENRE
                  </th>
                  <th className="autnh-1 border-r border-gray-600">MOOD</th>
                  <th className="autnh-1 border-r border-gray-600">LYRICIST</th>
                  <th className="autnh-1 border-r border-gray-600">
                    MUSIC DIRECTOR
                  </th>
                  <th className="autnh-1 border-r border-gray-600">SINGER</th>
                  <th className="autnh-1 border-r border-gray-600">PRODUCER</th>
                  <th className="autnh-1 border-r border-gray-600">
                    DESCRIPTION
                  </th>
                  <th className="autnh-1 border-r border-gray-600">TAGS</th>
                  <th className="autnh-1 border-r border-gray-600">OTHERS</th>
                  <th className="autnh-1 border-r border-gray-600">
                    COPY RIGHTS
                  </th>
                  <th className="autnh-1 border-r border-gray-600">
                    PUBLISHING RIGHTS
                  </th>
                  <th className="autnh-1 border-r border-gray-600">KEYWORDS</th>
                  <th className="autnh-1 border-r border-gray-600">RATINGS</th>
                  <th className="autnh-1 border-r border-gray-600">
                    TRACK NAME
                  </th>
                  <th className="autnh-1 rounded-r-md ">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-0.5 bg-transparent"></tr>
              </tbody>
              <tbody>
                <tr>
                  <td className="autnr rounded-l-md border-r border-gray-600">
                    test
                  </td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr border-r border-gray-600">7+</td>
                  <td className="autnr border-r border-gray-600">test</td>
                  <td className="autnr rounded-r-md">
                    <button className="text-blue-500" onClick={handleEditClick}>
                      Edit
                    </button>
                    {showComponent && <Popup type="video" />}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
